import LocalizedStrings from 'react-localization';

export const localText = new LocalizedStrings({
  en: {
    units: {
      ounce: {
        full: 'ounce',
        plural: 'ounces',
        short: 'oz'
      },
      hour: {
        full: 'hour',
        plural: 'hours',
        short: 'hr'
      },
      minute: {
        full: 'minute',
        plural: 'minutes',
        short: 'min'
      },
      second: {
        full: 'second',
        plural: 'seconds',
        short: 'sec'
      },
      kick: {
        full: 'kick',
        plural: 'kicks',
        short: 'K'
      },
      left: {
        full: 'Left',
        short: 'L'
      },
      right: {
        full: 'Right',
        short: 'R'
      }
    },
    returnToDash: 'Return to my dashboard',
    readingTime: 'Reading Time: {0} minutes',
    signedout: "You've been signed out.",
    somethingWrong: 'Something went wrong.',
    reloadCTA: 'Click the button below to continue.',
    reloginCTA: 'Click the button below to continue.',
    na: 'n/a',
    done: 'Done',
    finished: 'Finished',
    continue: 'Continue',
    complete: 'Complete',
    notComplete: 'Not completed',
    topics: 'Topics',
    quiz: 'Quiz',
    expandLesson: 'Expand lesson',
    start: 'Start',
    startTimer: 'Start timer',
    pause: 'Pause',
    edit: 'Edit',
    other: 'Other',
    duration: 'Duration',
    frequency: 'Frequency',
    newActivity: 'New activity',
    viewActivity: 'View activity',
    switchActivity: 'Change activity',
    hasPendingActivity: 'You have activity sessions in progress',
    /**
     * {0} - Activity title (From server)
     */
    continuePendingActivity: 'Continue {0} session',
    /**
     * {0} - `localText.ranges[day|week|month]`
     */
    noActivityFor: 'No activity to report for {0}',
    loading: 'Loading Tracking Data',
    export: {
      all: 'Export entries',
      /**
       * {0} - Activity title (From server)
       */
      type: 'Export {0} entries',
      inProgress: 'Building export file'
    },
    dashboard: {
      title: 'Activity Home',
      titleShort: 'Activity'
    },
    entry: {
      pending: 'Pending',
      valueNone: 'None',
      submitSuccess: 'Entry Created!',
      loadError: 'Failed to load entry',
      numberOfUnitIn: '{0} {1} in {2}',
      /**
       * {0} - Readable duration (30 min, 1 hr 20 min)
       */
      durationApart: '{0} apart',
      /**
       * {0} - Tool title or Activity Type (From server)
       */
      lastEntry: 'Last {0}',
      last: 'Last'
    },
    rangeSelection: {
      title: 'Entry Range'
    },
    ranges: {
      day: 'Today',
      week: 'Last 7 Days',
      month: 'Past Month'
    },
    overviews: {
      activity: {
        /**
         * {0} - `localText.ranges[day|week|month]`
         */
        titleAll: 'Activity overview for {0}',
        /**
         * {0} - Tool title (From server)
         * {1} - `localText.ranges[day|week|month]`
         */
        titleSingle: '{0} overview for {1}',
        /**
         * {0} - Type of entries (From server, "stool type", "stool color")
         */
        noInfoOfType: 'No {0} information to report.',
        noActivityCTA: 'Get started by adding a new entry',
        /**
         * {0} - Tool title (From server)
         */
        noEntriesCTA: "You haven't recorded a {0} today",
        entriesTotal: 'Total',
        sessionsTotal: 'Sessions',
        timeTotal: 'Total time',
        amountTotal: 'Total amount',
        bottlesTotal: 'Bottles',
        supplementsTotal: 'Feedings',
        view: 'View details',
        new: 'Add new'
      }
    },
    modals: {
      newActivity: {
        title: 'Select an activity',
        close: 'Cancel'
      }
    },
    charts: {
      entriesOverTime: {
        title: 'Activity over time',
        yAxisLabel: 'Number of Entries',
        xAxisLabel: 'Date'
      },
      diaperTypesOverTime: {
        title: 'Diaper types',
        yAxisLabel: 'Count',
        xAxisLabel: 'Date'
      },
      stoolTypesOverTime: {
        title: 'Stool types',
        yAxisLabel: 'Count',
        xAxisLabel: 'Date'
      },
      feedingTimes: {
        title: 'Time spent breastfeeding',
        yAxisLabel: 'Time',
        xAxisLabel: 'Date'
      },
      pumpingTimes: {
        title: 'Time spent pumping',
        yAxisLabel: 'Time',
        xAxisLabel: 'Date'
      }
    },
    activityFeed: {
      title: 'Activity'
    },
    forms: {
      submit: 'Submit',
      submitError: 'Submission Error',
      submitSuccess: '{0} recorded',
      submitSuccessInstructions: 'Remain on the page or press the "Next" button to record your next {0}',
      nextSubmit: 'Next {0}',
      leaveCreate: 'View activity',
      inProgress: "You're progress is being recorded. You can leave this page and navigate back later to complete.",
      fields: {
        color: {
          otherTitle: 'Choose your color'
        },
        feedingDetails: {
          side: 'Side',
          sideLeftAbbr: 'L',
          sideLeft: 'Left',
          sideLeftFull: 'Left Breast',
          sideRightAbbr: 'R',
          sideRight: 'Right',
          sideRightFull: 'Right Breast',
          sideBothAbbr: 'B',
          sideBoth: 'Both',
          sideBothFull: 'Both Breasts',
          play: 'Start',
          pause: 'Pause',
          amount: 'Amount',
          amountLeft: 'Amount Left',
          amountRight: 'Amount Right',
          inputTitle: 'Manual Input',
          recordTitle: 'Time a Session',
          total: 'Total Time',
          switch: 'Switch Sides',
          deleteRecord: 'Delete feeding record {0}'
        },
        dateTime: {
          dateLabel: 'Date',
          dateKeyboardInstructions: 'Use your arrow keys to navigate dates, then press enter to select the date. Begin by pressing the up or down arrow.',
          timeLabel: 'Time'
        },
        kickCounter: {
          add: 'Add kick',
          complete: 'You have reached 10 kicks! Rate the movement strength and then press the "Complete kick session" button.'
        },
        contractionTimer: {
          complete: 'Contraction finished. Select the appropriate intensity below and then press the "Complete contraction" button.'
        },
        kickStrength: {
          valueLabel: '{0} out of 5',
          valueLabel1: 'Gentle',
          valueLabel5: 'Mighty'
        }
      }
    },
    subscribe: 'Subscribe',
    noThanks: 'No thanks',
    restart: 'Restart',
    stories: {
      birthDate: 'Birth date',
      dueDate: 'Expected due date',
      newsletter: 'Bump to Baby Weekly Newsletter',
      newsletterLead: "Don't miss a thing! Get weekly personalized recommendations, tips, and fun facts to guide you from pregnancy to birth and beyond.",
      selectStage: 'Select your stage',
      stagePregnancy: 'I am expecting',
      stagePostpartum: 'I had my baby',
      accountSetupComplete: 'Account Setup Complete',
      accountSetupMessage: 'You did it! If you need to update any of this information, you can find it in your settings.',
      accountSetupAfterCta: 'Start exploring content',
      dailyComplete: 'Daily objective complete',
      todaysObjectives: "Today's Objectives",
      viewObjectives: 'View Objectives',
      hideObjectives: 'Hide Objectives',
      dailyToComplete: 'daily tasks to complete',
      allDailyComplete: 'All daily tasks are complete',
      toggleObjectivesModal: 'Toggle daily objectives modal'
    },
    mindfulMinute: {
      prestepOne: 'Take a deep cleansing breath.',
      prestepTwo: 'Read your affirmation.',
      breatheIn: 'Breathe In',
      breatheInFor: 'Breathe in for 4 seconds...',
      breatheOut: 'Breathe Out',
      breatheOutFor: 'Breathe out for 6 seconds...',
      tapToBegin: 'Tap to begin',
      tapToRestart: 'Tap Restart to begin again.',
      complete: 'Minute Complete'
    }
  },
  es: {
    units: {
      ounce: {
        full: 'onza',
        plural: 'onzas',
        short: 'oz'
      },
      hour: {
        full: 'hora',
        plural: 'horas',
        short: 'hr'
      },
      minute: {
        full: 'minuto',
        plural: 'minutos',
        short: 'min'
      },
      second: {
        full: 'segundo',
        plural: 'segundos',
        short: 's'
      },
      kick: {
        full: 'patada',
        plural: 'patada',
        short: 'P'
      },
      left: {
        full: 'Izquierdo',
        short: 'I'
      },
      right: {
        full: 'Derecho',
        short: 'D'
      }
    },
    returnToDash: 'Regresar a mi panel de control',
    readingTime: 'Tiempo de lectura: {0} minutos',
    signedout: "You've been signed out.",
    somethingWrong: 'Something went wrong.',
    reloadCTA: 'Click the button below to reload the page.',
    reloginCTA: 'Click the button below to continue.',
    na: 'no aplicable',
    done: 'terminadoa',
    finished: 'Terminado',
    continue: 'Continuar',
    complete: 'Completado',
    notComplete: 'No completado',
    topics: 'Temas',
    quiz: 'Quiz',
    expandLesson: 'Expandir lección',
    start: 'Empezar',
    startTimer: 'Empezar el cronómetro',
    edit: 'Editar',
    duration: 'Duración',
    frequency: 'Frecuencia',
    newActivity: 'Nueva actividad',
    viewActivity: 'Ver actividad',
    switchActivity: 'Cambiar actividad',
    hasPendingActivity: 'Tienes sesiones de actividad en progreso.',
    /**
     * {0} - Activity title (From server)
     */
    continuePendingActivity: 'Continuar sesión de {0}',
    /**
     * {0} - `localText.ranges[day|week|month]`
     */
    noActivityFor: 'No hay actividad que informar para {0}',
    loading: 'Cargando datos de rastreo',
    export: {
      all: 'Exportar entradas',
      /**
       * {0} - Activity title (From server)
       */
      type: 'Exportar {0} entradas',
      inProgress: 'Construyendo archivo de exportación'
    },
    dashboard: {
      title: 'Página de inicio: actividad',
      titleShort: 'Actividad'
    },
    entry: {
      pending: 'Pendiente',
      valueNone: 'Ninguno',
      submitSuccess: '¡Entrada creada!',
      loadError: 'Error al cargar la entrada',
      numberOfUnitIn: '{0} {1} en {2}',
      /**
       * {0} - Readable duration (30 min, 1 hr 20 min)
       */
      durationApart: '{0} apart',
      /**
       * {0} - Tool title or Activity Type (From server)
       */
      lastEntry: 'Last {0}',
      last: 'Last'
    },
    rangeSelection: {
      title: 'Rango de entrada'
    },
    ranges: {
      day: 'Hoy',
      week: 'Los últimos 7 días',
      month: 'El mes pasado'
    },
    overviews: {
      activity: {
        /**
         * {0} - `localText.ranges[day|week|month]`
         */
        titleAll: 'Resumen de actividad para {0}',
        /**
         * {0} - Tool title (From server)
         * {1} - `localText.ranges[day|week|month]`
         */
        titleSingle: 'Resumen de {0} para {1}',
        /**
         * {0} - Type of entries (From server, "stool type", "stool color")
         */
        noInfoOfType: 'No {0} information to report.',
        noActivityCTA: 'Empieza añadiendo una nueva entrada',
        /**
         * {0} - Tool title (From server)
         */
        noEntriesCTA: "You haven't recorded a {0} today",
        entriesTotal: 'Total',
        sessionsTotal: 'Sessions',
        timeTotal: 'Total time',
        amountTotal: 'Total amount',
        bottlesTotal: 'Bottles',
        supplementsTotal: 'Feedings',
        view: 'View details',
        new: 'Add new'
      }
    },
    modals: {
      newActivity: {
        title: 'Selecciona una actividad',
        close: 'Cerrar'
      }
    },
    charts: {
      entriesOverTime: {
        title: 'Actividad a lo largo del tiempo',
        yAxisLabel: 'Número de entradas',
        xAxisLabel: 'Fecha'
      },
      diaperTypesOverTime: {
        title: 'Tipos de pañales',
        yAxisLabel: 'cuenta',
        xAxisLabel: 'Fecha'
      },
      stoolTypesOverTime: {
        title: 'Tipos de heces',
        yAxisLabel: 'Count',
        xAxisLabel: 'Fecha'
      },
      feedingTimes: {
        title: 'Tiempo dedicado a amamantar',
        yAxisLabel: 'Hora',
        xAxisLabel: 'Fecha'
      },
      pumpingTimes: {
        title: 'Tiempo dedicado al bombeo',
        yAxisLabel: 'Hora',
        xAxisLabel: 'Fecha'
      }
    },
    activityFeed: {
      title: 'Actividad'
    },
    forms: {
      submit: 'Someter',
      submitError: 'Error de sumisión',
      submitSuccess: '{0} grabado',
      submitSuccessInstructions: 'Permanece en la página o presiona el botón "Siguiente" para grabar tu próximo {0}',
      nextSubmit: 'Próximo {0}',
      leaveCreate: 'Ver actividad',
      inProgress: 'Tu progreso está siendo registrado. Puedes salir de esta página y volver más tarde para completarla.',
      fields: {
        feedingDetails: {
          side: 'Costado',
          sideLeftAbbr: 'I',
          sideLeft: 'Izquierdo',
          sideLeftFull: 'Seno izquierdo',
          sideRightAbbr: 'D',
          sideRight: 'Derecho',
          sideRightFull: 'Seno derecho',
          sideBothAbbr: 'A',
          sideBoth: 'Ambos',
          sideBothFull: 'Ambos senos',
          play: 'Empezar',
          pause: 'Pausa',
          amount: 'Cantidad',
          amountLeft: 'Cantidad derecha',
          amountRight: 'Cantidad izquierda',
          inputTitle: 'Entrada manual',
          recordTitle: 'Medir el tiempo de una sesión',
          total: 'Tiempo total',
          switch: 'Cambio de lados',
          deleteRecord: 'Borrar registro de alimentación {0}'
        },
        dateTime: {
          dateLabel: 'Fecha',
          dateKeyboardInstructions: 'Use las teclas de flecha para navegar por las fechas, luego presione Intro para seleccionar la fecha. Comience presionando la flecha hacia arriba o hacia abajo.',
          timeLabel: 'Hora'
        },
        kickCounter: {
          add: 'Agregar patada',
          complete: '¡Has alcanzado las 10 patadas! Someter el formulario para finalizar la sesión.'
        },
        contractionTimer: {
          complete: 'Contracción terminada. Selecciona la intensidad apropiada a continuación y luego presiona el botón "Terminar contracción".'
        },
        kickStrength: {
          valueLabel: '{0} de 5',
          valueLabel1: 'Suave',
          valueLabel5: 'Poderoso'
        }
      }
    },
    subscribe: 'Suscríbete',
    noThanks: 'No, gracias',
    restart: 'Reiniciar',
    stories: {
      birthDate: 'Fecha de nacimiento',
      dueDate: 'fecha probable de parto',
      newsletter: 'Boletín semanal de Bump to Baby',
      newsletterLead: '¡No te pierdas nada! Obtén recomendaciones personalizadas semanalmente, consejos y datos divertidos para guiarte desde el embarazo hasta el parto y más allá.',
      selectStage: 'Selecciona tu etapa',
      stagePregnancy: 'Estoy embarazada',
      stagePostpartum: 'Tuve a mi bebe',
      accountSetupComplete: 'Configuración de cuenta completa',
      accountSetupMessage: '¡Lo hiciste! Si necesitas actualizar alguna parte de esta información, puedes encontrarla en las configuraciones.',
      accountSetupAfterCta: 'Empieza a explorar el contenido',
      dailyComplete: 'Objetivo diario completado',
      todaysObjectives: 'Objetivos de hoy',
      viewObjectives: 'Ver los objetivos',
      hideObjectives: 'Esconder los objetivos',
      dailyToComplete: 'tareas diarias para completar',
      allDailyComplete: 'Todas las tareas diarias están completas',
      toggleObjectivesModal: 'Alternar modal de objetivos diarios'
    },
    mindfulMinute: {
      prestepOne: 'Respira profundamente.',
      prestepTwo: 'Lee tu afirmación.',
      breatheIn: 'Inhala',
      breatheInFor: 'Inhala por 4 segundos...',
      breatheOut: 'Exhala',
      breatheOutFor: 'Exhala por 6 segundos...',
      tapToBegin: 'Toca para comenzar',
      tapToRestart: 'Toca Reiniciar para comenzar de nuevo.',
      complete: 'Minuto completo'
    }
  }
});
