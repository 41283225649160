// Third party integration
export const recharts = "recharts";

export const sweetalert = "sweetalert";

export const datetime = "datetime";

// Internal Modules (For loading segmentation)
export const activityModule = "activityModule";

export const ldContentModule = "ldContentModule";

export const formModule = "formModule";

export const userstoriesModule = "userstoriesModule";