let trackingAvailable;

const isTrackingAvailable = () => {
    if( typeof trackingAvailable === "undefined" ) trackingAvailable = "gtag" in window && typeof UACode === "string";
    return trackingAvailable;
}

export const trackViewPage = () => {
    if( !isTrackingAvailable() ) {
        console.log("Page View", document.title,window.location.href);
        return;
    }
    window.gtag('get', UACode, "page_location", (prev_location) => {
        window.gtag('config', UACode, {
            'page_title': document.title,
            'page_location': window.location.href,
            'page_path': location.pathname
        });
    });
}

export const trackEvent = ( action, details ) => {
    if( !isTrackingAvailable() ) {
        console.log("Event", action, details);
        return;
    }
    window.gtag('event', action, details);
}