import React, { useMemo } from "react";

import "./CardBox.sass";

export const CardBox : CardBoxComponent = ({
    className = "",
    header: HeaderComponent = undefined,
    body: BodyComponent = undefined,
    footer: FooterComponent = undefined,
    scheme = "",
    bodyGutter = 0
}) => {
    const cardProps = useMemo(() => {
        return {
            card: {
                className: [
                    className,
                    `card card-box`,
                    ...(scheme ? [`is-${scheme}`] : [])
                ].join(" ")
            },
            ...(HeaderComponent ? {
                cardHeader: {
                    className: `card-header`
                }
            }: {} ),
            ...(BodyComponent ? {
                cardBody: {
                    className: `card-body p-${bodyGutter}`
                }
            }: {} )
        }
    }, [className, scheme, HeaderComponent]);
    return (
        <div {...cardProps.card}>
            {HeaderComponent && (
                <div {...cardProps.cardHeader}>
                    { typeof HeaderComponent === "function" ? <HeaderComponent /> : HeaderComponent }
                </div>
            )}
            {BodyComponent && (
                <div {...cardProps.cardBody}>
                    { typeof BodyComponent === "function" ? <BodyComponent /> : BodyComponent }
                </div>
            )}
            {FooterComponent && (
                <div className="card-footer">
                    { typeof FooterComponent === "function" ? <FooterComponent /> : FooterComponent }
                </div>
            )}
        </div>
    )
}