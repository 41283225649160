import React, { useMemo } from "react";

import { Icons, SvgIcon } from "../svgs";

import "./Icon.sass";

export const IconEl : IconElComponent = ({ icon, label = "", lib = "fas", className = "", style = {} }) => {

    if( icon in Icons ) {
        return (
            <span className={`${className} ratio ratio-1x1 icon-el`} style={style} aria-hidden>
                <span className="d-flex align-items-center">
                    <SvgIcon icon={icon} />
                    {label && <span className="visually-hidden">{label}</span>}
                </span>
            </span>
        )
    }

    return <i className={[className,`${lib} fa-${icon}`].join(' ')} style={style} aria-hidden></i>
};

export const IconBox : IconBoxComponent = ({
    icon,
    lib = "fas",
    scheme = "light",
    gutter = 2,
    className = "",
    fill = false
}) => {

    const props = useMemo(() => {
        const isLight = scheme === "light";

        return {
            box: {
                className: [
                    className,
                    `icon-box p-${gutter} bg-${scheme}`,
                    ...(fill ? ['fill-icon'] : '')
                ].join(" ")
            },
            icon: {
                icon,
                lib,
                className: isLight ? "text-dark" : `text-${scheme}`
            }
        }
    }, [icon, lib, scheme]);

    return <div {...props.box}><IconEl {...props.icon} /></div>;

};