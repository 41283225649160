
import React, { useEffect, useMemo, useState } from "react";
import { LoadingPulse } from "../../components";
import { moduleCache } from "./async";
import { fetchModules } from "./fetch";

export const ModuleProvider = ({
    modules,
    dependent: DependentComponet,
    error: ErrorComponent = () => <div>Module loading error.</div>,
    loading: LoadingComponent = () => <LoadingPulse />,
    onLoaded = () => { },
    onError = () => { }
}) => {

    const unloaded = useMemo(() => {
        const unloaded = modules.filter(module => !moduleCache.has(module) || !moduleCache.get(module));

        if (unloaded.length) for (const module of unloaded) moduleCache.set(module, false);

        return unloaded;
    }, []);

    const [loadingState, setLoadingState] = useState<number|false>(() => unloaded.length ? 0 : 1);

    useEffect(() => {
        if( !unloaded.length ) return;
        (async () => {
            try {

                const loadedModules = await fetchModules(unloaded);

                for (const i in unloaded) moduleCache.set(unloaded[i], loadedModules[i]);

                setLoadingState(1);

            } catch (err) {
                setLoadingState(false)
            }
        })();
    }, [unloaded]);

    useEffect(() => {
        if (loadingState === false) {
            onError();
        } else if (loadingState) {
            onLoaded()
        }
    }, [loadingState]);

    return loadingState === false
    
        ? <ErrorComponent />
        
        : ( loadingState ? (
            typeof DependentComponet === "function" ? <DependentComponet /> : DependentComponet
         ) : <LoadingComponent /> )
}